import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-forgot-psw',
  templateUrl: './forgot-psw.component.html',
  styleUrls: ['./forgot-psw.component.css']
})
export class ForgotPSWComponent implements OnInit {
  emailIN: string = '';
  profileForm =  new FormGroup({
    email: new FormControl("", Validators.required)
})

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

}
