<div class="displayTable">
    <div class="displayTableCell">
        <div class="authBlock">
            <h2 class="text-center" style="margin-top: 3%;">Recupera Password</h2>
            <form [formGroup]="profileForm" (ngSubmit)="authService.ForgotPassword(profileForm.value)" style=" margin-bottom: 19%;   margin-top: 4%;">
                <div class="form-group">
                    <div class="input-group mb-3 formGroup text-center boxInput">
                        <input type="text" class="form-control" formControlName="email" id="email" aria-label="email" aria-describedby="basic-addon1" placeholder="Email" />

                    </div>
                </div>
                <div class="formGroup text-center" style=" text-align: center; margin-top: 2%;">
                    <button type="submit" class="btn btn-primary btnLog btn-lg" id="btnSave" [disabled]="!profileForm.valid">Recupera</button>
                </div>
            </form>

        </div>
    </div>