import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ForgotPSWRoutingModule } from './forgot-psw-routing.module';
import { ForgotPSWComponent } from './forgot-psw.component';
import { RouterTestingModule } from '@angular/router/testing';


@NgModule({
  declarations: [ForgotPSWComponent],
  imports: [
    CommonModule,
    ForgotPSWRoutingModule,
    ReactiveFormsModule,
    RouterTestingModule,
    RouterModule,
  ]
})
export class ForgotPSWModule { }
